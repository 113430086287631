import { sectionStyle } from 'holded/lib/styles';

export const defaultThemeValues = {
  background: '',
  titleColor: '',
  titleSecondaryColor: '',
  descriptionColor: '',
  descriptionGradientColor: '',
};

export const CTATheme: CTAThemeType = {
  cta: {
    background: {
      light: sectionStyle.background.light,
      soft: sectionStyle.background.soft,
      gradient: sectionStyle.background.gradient,
      dark: sectionStyle.background.dark,
    },
    titleColor: {
      light: sectionStyle.textColor.default,
      soft: sectionStyle.textColor.default,
      gradient: sectionStyle.textColor.light,
      dark: sectionStyle.textColor.light,
    },
    titleSecondaryColor: {
      light: sectionStyle.textColor.primary,
      soft: sectionStyle.textColor.primary,
      dark: sectionStyle.textColor.blueSoft,
    },
    descriptionColor: {
      light: sectionStyle.textColor.secondary,
      soft: sectionStyle.textColor.secondary,
      gradient: sectionStyle.textColor.soft,
      dark: sectionStyle.textColor.soft,
    },
    descriptionGradientColor: {
      light: sectionStyle.textColor.secondary,
      soft: sectionStyle.textColor.secondary,
      gradient: sectionStyle.textColor.light,
      dark: sectionStyle.textColor.soft,
    },
  },
};

export type CTAThemeType = {
  cta: {
    background: {
      light: string;
      soft: string;
      gradient: string;
      dark: string;
    };
    titleColor: {
      light: string;
      soft: string;
      gradient: string;
      dark: string;
    };
    titleSecondaryColor: {
      light: string;
      soft: string;
      dark: string;
    };
    descriptionColor: {
      light: string;
      soft: string;
      gradient: string;
      dark: string;
    };
    descriptionGradientColor: {
      light: string;
      soft: string;
      gradient: string;
      dark: string;
    };
  };
};
