import dynamic from 'next/dynamic';
import { useEffect, useState } from 'react';

import { getThemeStyles } from 'holded/lib/styles';
import { BackgroundWithGradient, CtaSection } from 'holded/modules/cms/domain/components/cta';
import { CTATheme, defaultThemeValues } from 'holded/modules/cms/ui/sections/cta/CTATheme';

import styles from './CTA.module.scss';

const Button = dynamic(() => import('holded/modules/cms/ui/shared/components/Button'));
const NextImage = dynamic(() => import('holded/modules/cms/ui/shared/components/Image'));
const classNames = (...classes: string[]) => classes.join(' ');
const CTA1 = ({ title, description, image, buttons, background }: CtaSection<BackgroundWithGradient>) => {
  const [themeValues, setThemeValues] = useState(defaultThemeValues);

  const link = buttons?.find((button) => button)?.url;

  useEffect(() => {
    const themeStyles = getThemeStyles(CTATheme.cta, background);
    setThemeValues({ ...themeValues, ...themeStyles });
  }, []);

  return (
    <div onClick={() => window.open(link, '_self')} className="bg-white md:pointer-events-none cursor-pointer">
      <div className={`max-w-7xl mx-auto py-16 px-4 sm:px-6 lg:px-8`}>
        <div
          className={classNames(
            'text-h3 rounded-lg shadow-xl overflow-hidden lg:grid lg:grid-cols-2 lg:gap-4',
            background == 'gradient' ? styles[background] : themeValues.background,
          )}
        >
          <div className="pt-10 pb-12 px-6 sm:pt-16 sm:px-16 lg:py-16 lg:pr-0 xl:py-20 xl:px-20">
            <div className="lg:self-center">
              <p className={`${themeValues.titleColor} text-h4 md:text-h3 font-extrabold`}>
                <span className="block">{title}</span>
              </p>
              {description && <p className={`${themeValues.descriptionColor} text-b2 mt-4 leading-6`}>{description}</p>}
              {buttons?.map((button) => (
                <Button key={button.id} data={button} extraClass={'mt-8 pointer-events-auto'}>
                  {button.text}
                </Button>
              ))}
            </div>
          </div>
          <div className="-mt-6 h-auto">
            {image?.data && (
              <NextImage
                media={image.data}
                classImg={
                  'transform translate-x-6 translate-y-6 rounded-md object-cover sm:translate-x-16 lg:translate-y-20'
                }
                width={600}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CTA1;
